import React from "react"
import "./Energies.css"

export default function Energies({ day, month, year }) {
  const reduceTo22 = (num) => {
    // Only reduce the number if it is greater than 22
    if (num <= 22) {
      return num
    }
    while (num > 22) {
      num = num
        .toString()
        .split("")
        .reduce((sum, digit) => sum + parseInt(digit), 0)
    }
    return num
  }

  const firstP = Number(reduceTo22(day))
  const secondP = Number(reduceTo22(month))
  const thirdP = Number(reduceTo22(year))
  const fourthP = reduceTo22(firstP + secondP + thirdP)
  const fifthP = reduceTo22(firstP + secondP + thirdP + fourthP)
  const sixthP = reduceTo22(firstP + secondP + thirdP + fourthP + fifthP)

  const firstGap = reduceTo22(firstP + secondP)
  const secondGap = reduceTo22(secondP + thirdP)
  const thirdGap = reduceTo22(thirdP + fourthP)
  const fourthGap = reduceTo22(fourthP + fifthP)
  const fifthGap = reduceTo22(fifthP + firstP)

  const firstSemi = reduceTo22(firstP + firstGap)
  const secondSemi = reduceTo22(firstGap + secondP)
  const thirdSemi = reduceTo22(secondP + secondGap)
  const fourthSemi = reduceTo22(secondGap + thirdP)
  const fifthSemi = reduceTo22(thirdP + thirdGap)
  const sixthSemi = reduceTo22(thirdGap + fourthP)
  const seventhSemi = reduceTo22(fourthP + fourthGap)
  const eighthSemi = reduceTo22(fourthGap + fifthP)
  const ninethSemi = reduceTo22(fifthP + fifthGap)
  const tenthSemi = reduceTo22(fifthGap + firstP)

  return (
    <div className="component-container">
      <div className="energy-container" id="text-container">
        <div
          className={`energy-section ${firstP === 1 ? "" : "hidden"}`}
          id="1"
        >
          <strong className="energy-title">ПЪРВА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Лидери, новатори, стремят се да бъдат номер 1 във всичко;</li>
            <li>Оптимисти, позитивни хора;</li>
            <li>Генератори на идеи;</li>
            <li>Решителни и волеви, лесно се адаптират към новите условия;</li>
            <li>
              Успешните хора, заемат високи позиции, отлични управители, умеят
              да ръководят другите.
            </li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Гордост, егоизъм, нарцисизъм, признание само на собствената
              значимост;
            </li>
            <li>Те не приемат критика, не признават конкуренцията;</li>
            <li>
              Подчиняват другите, налагат своята воля, осъществяват натиск върху
              другите;
            </li>
            <li>Самотници: "Аз сам";</li>
            <li>
              Страх от започване на нов бизнес, изоставяне на започнатото;
            </li>
            <li>
              Безсилие, липса на вяра в лидерските качества, нежелание за
              реализиране на потенциала, мързел, апатия.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 2 ? "" : "hidden"}`}
          id="2"
        >
          <strong className="energy-title">ВТОРА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Интуиция, дълбоко чувстват другите, емпатия;</li>
            <li>Емоционалност;</li>
            <li>
              Дипломати, знаят как да изгладят грубите ръбове и да намерят
              подход към всеки човек;
            </li>
            <li>Дискретни, спокойни, сдържани, мистериозни личности;</li>
            <li>Имат лечителски и езотерични способности;</li>
            <li>Силна връзка с природата;</li>
            <li>Добри оратори.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Потайни, недоверчиви;</li>
            <li>
              Те се съмняват, нерешителни са, оставят се да бъдат водени,
              пасивни са в живота;
            </li>
            <li>Нуждаят се от любов отвън;</li>
            <li>
              Те съзнателно се отказват от майчинството и не искат да имат деца;
            </li>
            <li>
              Те клюкарстват, говорят зад гърба на другите и могат да бъдат
              ядосани на целия свят;
            </li>
            <li>Противоречиви, двулични;</li>
            <li>Твърде натрапчиви;</li>
            <li>Манипулатори, „жертви”;</li>
            <li>Склонност към изпадане в зависимост от другите.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 3 ? "" : "hidden"}`}
          id="3"
        >
          <strong className="energy-title">ТРЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Ярки и топли, позитивни и емоционални хора;</li>
            <li>
              Артистични и креативни, имат богато въображение, чувство за вкус и
              стил;
            </li>
            <li>Добри управители, лидери;</li>
            <li>Те чувстват себе си като крале в живота;</li>
            <li>Проявяват грижа и нежност към другите;</li>
            <li>Семейството е важно за тях, обичат децата;</li>
            <li>Тактилни</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Гордост и високомерие, оценяват другите по статус;</li>
            <li>
              Контрол над близките, власт, доминират както в работата, така и в
              семейството;
            </li>
            <li>
              Свръхемоционалност, истеричност, създаване на несъществуващи
              проблеми поради богато въображение;
            </li>
            <li>Фокус на реализация и отрича другите сфери на живота;</li>
            <li>Зависимост от отношения;</li>
            <li>
              Жените не приемат женската си същност, потискат мъжете, имат
              проблеми с майка си и другите жени.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 4 ? "" : "hidden"}`}
          id="4"
        >
          <strong className="energy-title">ЧЕТВЪРТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Мъжественост, силно вътрешно ядро ( мисли, вярвания, убеждения);
            </li>
            <li>Трудолюбие, сила;</li>
            <li>Отговорност, систематичност, ред във всичко;</li>
            <li>Стабилност, самодостатъчност;</li>
            <li>
              Способен да взема информирани решения, дори в най-трудните
              ситуации;
            </li>
            <li>Отлични лидери в работата, глава на семейство;</li>
            <li>Те не се страхуват от никаква работа.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Външна безчувственост и сухота;</li>
            <li>
              Авторитаризъм, прекомерен контрол върху близките, лишаване на
              другите от правото на избор и свобода;
            </li>
            <li>
              Мания за материални неща, постигане на власт с всякакви средства и
              начини;
            </li>
            <li>Злоупотреба с власт, деспотизъм;</li>
            <li>Липса на връзка с тялото;</li>
            <li>Проблемни отношения с бащата и други мъже;</li>
            <li>
              Жените могат да изпитват трудности при изграждането на връзки,
              защото потискат партньорите си и привличат слаби мъже.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 5 ? "" : "hidden"}`}
          id="5"
        >
          <strong className="energy-title">ПЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Умерен консерватизъм;</li>
            <li>
              Уважават традициите, нормите и устоите, като внасят нещо ново в
              тях;
            </li>
            <li>
              Притежават аналитично мислене, способност за пресмятане и
              прогнозиране;
            </li>
            <li>
              Постоянно се нуждаят от нова информация, любопитни и разнострани;
            </li>
            <li>
              Те обичат да учат и притежават дарбата на учител – способността да
              предават сложна информация на прост език;
            </li>
            <li>Добри оратори.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Прекалено свободолюбие и желание за постоянно разнообразие;</li>
            <li>Излишен консерватизъм, упорита съпротива срещу всичко ново;</li>
            <li>
              Неумение да признаят, че не са прави, налагат своите възгледи и
              принципи, стремят се да поучават всички;
            </li>
            <li>
              Те изпитват интелектуална гордост, оценяват хората по техния
              интелект;
            </li>
            <li>Те се разпиляват и не завършват започнатото;</li>
            <li>Имат неспокоен ум, склонни са към тревожност и безсъние;</li>
            <li>
              Сексуално затворени хора или изпитват нужда от разнообразие;
            </li>
            <li>Не са тактилни.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 6 ? "" : "hidden"}`}
          id="6"
        >
          <strong className="energy-title">ШЕСТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Надарени с естествена красота;</li>
            <li>Те ценят хармонията, комфорта и красотата във всичко;</li>
            <li>
              Те имат отлично чувство за вкус и стил, привлича ги изкуството;
            </li>
            <li>Мили, приятни, доброжелателни, неконфликтни;</li>
            <li>Те имат “златни ръце” (умеят да творят с ръцете си);</li>
            <li>Имат дарбата да убеждават;</li>
            <li>Създават уют в дома и обичат да приемат гости.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Обсебеност от връзки и удоволствия в живота;</li>
            <li>Повърхностност, „розови очила”, необосновано идеализиране;</li>
            <li>
              Раними, обидчиви, подозрителни, капризни, склони към депресия;
            </li>
            <li>Придирчивост към другите;</li>
            <li>
              Те са обсебени от външния си вид, постоянно се сравняват с другите
              и имат комплекси;
            </li>
            <li>Не се обичат и се обезценяват, ниска самооценка.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 7 ? "" : "hidden"}`}
          id="7"
        >
          <strong className="energy-title">СЕДМА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Енергията на воина, победителя, революционера;</li>
            <li>Бързи по отношение на изпълнение на действията;</li>
            <li>Активни, смели, независими, целеустремени хора;</li>
            <li>
              Обичат пътуванията, активния начин на живот, стремят се към
              постоянно развитие и движение;
            </li>
            <li>Интуиция, проницателност, усещат лъжата;</li>
            <li>Способност да стигнем до същността на нещата;</li>
            <li>Те имат подкрепата на висшите сили (ангели).</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Мързел, апатия, липса на движение и развитие в живота,
              неудовлетвореност, нереализираност;
            </li>
            <li>Разпиляване, липса на концентрация;</li>
            <li>Безразличие към тялото и здравето си;</li>
            <li>Склонност към зависимости;</li>
            <li>
              Потайни, емоционално недостъпни, недоверчиви, мнителни, търсещи
              лъжи там, където ги няма;
            </li>
            <li>
              Те рязко разделят света на черно и бяло, добро и зло и могат да
              бъдат враждебни;
            </li>
            <li>
              Прекомерна активност, постоянно надбягване, работохолизъм, водещ
              до нервни сривове и пренапрежение.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 8 ? "" : "hidden"}`}
          id="8"
        >
          <strong className="energy-title">ОСМА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Спазват законите, ценят морала и нравствеността;</li>
            <li>Честни и порядъчни;</li>
            <li>Имат чувство за дълг и отговорност;</li>
            <li>Разбират причинно-следствените връзки, закона на кармата;</li>
            <li>Трудолюбиви, работят усилено за статус и пари;</li>
            <li>Защитават слабите;</li>
            <li>
              Богати, успешни, изграждат собствен голям бизнес, системи,
              управляват много пари.
            </li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Мания за пари;</li>
            <li>Осъждане, обвиняване на всички наоколо за техните проблеми;</li>
            <li>
              Считат, че хората са им длъжни и постоянно очакват нещо в замяна
              от тях;
            </li>
            <li>Борба със света за справедливост;</li>
            <li>
              Страх от предателство и измама, или самите те предават и мамят;
            </li>
            <li>Желание за нарушаване на закона;</li>
            <li>Конфликтност, търсят лошото в хората;</li>
            <li>Те не знаят как да се отпускат;</li>
            <li>Желание за спестяване на пари или, обратно, прахосване</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 9 ? "" : "hidden"}`}
          id="9"
        >
          <strong className="energy-title">ДЕВЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Природна мъдрост, знание, философски поглед върху света;</li>
            <li>
              Съчувствие и емпатия, отзивчивост, чувствителност към чуждата
              болка;
            </li>
            <li>
              Езотеричен потенциал, добра памет, способност да виждат пророчески
              сънища, да разчитат знаци;
            </li>
            <li>
              Талантливи, те се задълбочават във всичко, което изучават, затова
              отлично се проявяват във всяка сфера;
            </li>
            <li>Самодостатъчни, изпълват се с енергия, когато са сами;</li>
            <li>Амбициозни.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Затвореност, откъснатост, избягване на контакти с външния свят;
            </li>
            <li>Нежелание да помагат и да споделят своите знания и мъдрост;</li>
            <li>
              Аскетизъм, пренебрежение към външния вид, собствения дом или мания
              за лукс, пазаруване;
            </li>
            <li>
              Синдром на спасителя - готов да помогне на всеки в ущърб на себе
              си;
            </li>
            <li>
              Липса на баланс между духовната и материалната страна на живота;
            </li>
            <li>Синдром на самозванеца ( не съм достатъчно добър/готов).</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 10 ? "" : "hidden"}`}
          id="10"
        >
          <strong className="energy-title">ДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Успешни, късметлии и щастливци в живота;</li>
            <li>Активни, намират се в постоянно движение;</li>
            <li>Всички врати са отворени за тях;</li>
            <li>Авантюристични и с леко се придвижват в живота.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Апатични, мързеливи, безинициативни;</li>
            <li>
              Не вярват на света и хората, опитват се да контролират всичко,
              тревожни са;
            </li>
            <li>Те не вярват в себе си;</li>
            <li>Нерешителни, зависими и несамостоятелни.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 11 ? "" : "hidden"}`}
          id="11"
        >
          <strong className="energy-title">ЕДИНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Вътрешна и физическа сила;</li>
            <li>Активни, издръжливи хора;</li>
            <li>Лидерски потенциал, отлични организационни умения;</li>
            <li>
              Занимаващи се с благотворителна дейност и помагат на другите;
            </li>
            <li>Трудолюбиви и целеустремени.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Вътрешна агресия, която може да прерасне във външна агресия;
            </li>
            <li>Горещ нрав, раздразнителност;</li>
            <li>Свръхвъзбудимост, износване на тялото от спорт или работа;</li>
            <li>
              Враждебност, натиск върху хората, налагане на принципи, гордост и
              егоизъм;
            </li>
            <li>Те са в постоянна вътрешна борба.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 12 ? "" : "hidden"}`}
          id="12"
        >
          <strong className="energy-title">ДВАНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Креативни, творчески, необикновени хора, новатори;</li>
            <li>Те имат необичаен поглед върху познатите неща;</li>
            <li>Добри, грижовни, милосърдни, състрадателни;</li>
            <li>
              Те са извори на необичайна информация, мъдри и духовно развити;
            </li>
            <li>Отзивчиви, винаги насочени към взаимопомощ и подкрепа.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Изпадат в апатия, състояние на безтегловност, депресии;</li>
            <li>Имат негативен поглед за света;</li>
            <li>
              Проявяват обсебваща грижа и внимание, манипулират, за да привържат
              другите към себе си;
            </li>
            <li>Обезценяват се, не проявяват себелюбие;</li>
            <li>Те живеят за другите и се жертват.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 13 ? "" : "hidden"}`}
          id="13"
        >
          <strong className="energy-title">ТРИНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Безстрашни, рискуващи, смели, непредсказуеми;</li>
            <li>Способни да създават промяна около себе си;</li>
            <li>
              Те не се страхуват от трансформации, лесно се отказват от старото;
            </li>
            <li>
              Допринасят за прогреса и модернизацията, където и да се намират;
            </li>
            <li>Обичат екстремното;</li>
            <li>Помогнете за промяната на другите хора.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Резки, агресивни, жестоки и хладнокръвни;</li>
            <li>
              Те се страхуват от промяна, засядат в миналото, затрупват
              пространството с ненужни неща;
            </li>
            <li>
              Не ценят живота си, рискуват го неоправдано, безразсъдни са,
              безотговорни;
            </li>
            <li>Сеят унищожение и хаос навсякъде;</li>
            <li>
              Те се разпиляват и постоянно изоставят това, което са започнали;
            </li>
            <li>Фокусират се върху смъртта, страх за живота на близките;</li>
            <li>Склонни към зависимости.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 14 ? "" : "hidden"}`}
          id="14"
        >
          <strong className="energy-title">ЧЕТИРИНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Те фино усещат и възприемат света и другите, имат добра интуиция;
            </li>
            <li>Ценят хармонията и баланса във всичко;</li>
            <li>Имат богат вътрешен свят;</li>
            <li>
              Хора на творчеството, изкуството, възприемчиви към всичко красиво;
            </li>
            <li>Любознателни, интелигентни, многостранни;</li>
            <li>Развита способност за лечение;</li>
            <li>Дарба да обучават;</li>
            <li>Развити са и двете полукълба на мозъка: логика и чувства.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Прекомерност, алчност, липса на баланс;</li>
            <li>Невежество, разпуснатост, грубост;</li>
            <li>Изпадат в апатия и мързел, не искат да се развиват;</li>
            <li>Не вярват в себе си и в своя творчески потенциал;</li>
            <li>Приземеност;</li>
            <li>Затворени чувства и душа, оттегляне, изолация;</li>
            <li>Разпиляност.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 15 ? "" : "hidden"}`}
          id="15"
        >
          <strong className="energy-title">ПЕТНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Магнетични хора, привлекателни и притегателни;</li>
            <li>Те знаят как да се покажат публично и да спечелят другите;</li>
            <li>
              Те разчитат слабостите на другите хора, прозират несъвършенствата
              на хората, проницателни са;
            </li>
            <li>Имат дарбата да убеждават и са отлични търговци;</li>
            <li>Умеят да управляват ситуации и хора;</li>
            <li>Ценят красотата и лукса.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Власт, егоизъм, агресия, манипулиране на другите за собствена
              изгода;
            </li>
            <li>
              Мания за кариера, фиксация върху материалните неща, липса на
              духовно развитие;
            </li>
            <li>Желание за лесни пари;</li>
            <li>Податливи на изкушения и съблазни;</li>
            <li>Влизат в любовни триъгълници и имат зависимости;</li>
            <li>
              Могат да бъдат привлечени от всичко тъмно, незаконно, забранено;
            </li>
            <li>
              Другата крайност: срамежливост, затвореност, потисната
              сексуалност.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 16 ? "" : "hidden"}`}
          id="16"
        >
          <strong className="energy-title">ШЕСТНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Енергийно и духовно силни хора, способни да се справят с всяка
              криза, добри кризисни мениджъри;
            </li>
            <li>
              Те имат дарбата да строят, реконструират, създават от нулата;
            </li>
            <li>Структурирани, системни;</li>
            <li>Креативни, новатори;</li>
            <li>Лидери, които умеят да водят другите;</li>
            <li>Помагат на другите да се пробудят и да прозрат;</li>
            <li>Добро здраве.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Агресия, неконтролирани емоции, грубост, рязкост;</li>
            <li>Егоизъм, арогантност, надменност;</li>
            <li>
              Липса на духовно развитие, ниски възгледи и мислене, силна
              привързаност към материалните неща;
            </li>
            <li>Хаос в живота и действията;</li>
            <li>Повишен травматизъм, разрушителни ситуации в живота;</li>
            <li>Склонност към зависимост.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 17 ? "" : "hidden"}`}
          id="17"
        >
          <strong className="energy-title">СЕДЕМНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Ярки, необикновени и креативни хора;</li>
            <li>Талантливи, длъжни са да станат известни;</li>
            <li>Привличат другите, общителни, отзивчиви;</li>
            <li>Обичат природата;</li>
            <li>Възприемчиви, интуитивни;</li>
            <li>Имат богато въображение и фантазия;</li>
            <li>Проводници на енергия, могат да имат лечебни способности.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Те нямат абсолютно никаква вяра в себе си и своите таланти;</li>
            <li>Слабохарактерни, несамостоятелни;</li>
            <li>“Звездна болест”, надменост, напомпено его;</li>
            <li>Поставят се над другите поради своята изключителност;</li>
            <li>Липса на реална картина за света, витаят в облаците;</li>
            <li>
              Желание да присвоят чужд трудг (плагиатство), да се постигне слава
              за сметка на някой друг;
            </li>
            <li>Апатия, зависимост от мнението на други хора.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 18 ? "" : "hidden"}`}
          id="18"
        >
          <strong className="energy-title">ОСЕМНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Дълбоки, чувствителни, интуитивни, духовно развити хора;</li>
            <li>Имат езотерични способности;</li>
            <li>Богат вътрешен свят, добро въображение, жажда за красота;</li>
            <li>Те са привлечени към всичко необичайно и мистично;</li>
            <li>Липса на реална картина за света, витаят в облаците;</li>
            <li>
              Те обичат природата, зареждат се с вода и зависят от лунните
              цикли.
            </li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Податливи на депресия, емоционална нестабилност;</li>
            <li>Неувереност в себе си;</li>
            <li>Те имат много страхове и фобии;</li>
            <li>Те се изолират от външния свят, избягват контакт с хората;</li>
            <li>Те бягат от реалността в своя илюзорен свят;</li>
            <li>
              Те се фиксират върху негативността и я привличат в живота си;
            </li>
            <li>Те са лицемери, лъжат, манипулират другите.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 19 ? "" : "hidden"}`}
          id="19"
        >
          <strong className="energy-title">ДЕВЕТНАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Мощен енергиен потенциал, способността да светят и стоплят всички;
            </li>
            <li>Силни лидери;</li>
            <li>
              Умеят и обичат да бъдат център на вниманието, умеят да събират
              хора и екип около себе си;
            </li>
            <li>Поставят си амбициозни цели и работят за постигането им;</li>
            <li>Позитивни, отворени, истински жизнени, пълни с енергия;</li>
            <li>Занимават се със социална дейност и имат публични изяви.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Властни, агресивни;</li>
            <li>Проявяват гордост и налагат волята си на другите;</li>
            <li>
              Или оказват натиск върху другите, или самите те биват потискани;
            </li>
            <li>
              Страхуват се от големи проекти и се страхуват да поемат
              отговорност;
            </li>
            <li>
              Те са лишени от лекота, творчески полет, позитивност и не знаят
              как да се отпуснат;
            </li>
            <li>
              Те изпитват вътрешно самосъжаление, самокритика и проявяват
              неприязън към себе си.
            </li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 20 ? "" : "hidden"}`}
          id="20"
        >
          <strong className="energy-title">ДВАДЕСЕТА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Имат тясна връзка с рода;</li>
            <li>Имат силна интуиция и лечителски способности;</li>
            <li>
              Енергийните проводници, разчитат информацията от информационното
              поле и я предават на света;
            </li>
            <li>Енергийно силни, духовни и разностранноразвити;</li>
            <li>Те могат да видят пророчески сънища;</li>
            <li>Имат ораторски способности.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Много обидчиви и категорични;</li>
            <li>Озлобени и гневни на света, осъждат другите;</li>
            <li>Натиск върху другите или липса на воля и на вътрешно ядро;</li>
            <li>
              Обсебени от семейството, страх да не го загубят, зависимост в
              отношенията;
            </li>
            <li>
              Конфликти в рода, неуважение на рода и по-възрастните, неприемане
              на родителите;
            </li>
            <li>Неспособност да прощават;</li>
            <li>Нецензурни думи, клюки.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 21 ? "" : "hidden"}`}
          id="21"
        >
          <strong className="energy-title">ДВАДЕСЕТ И ПЪРВА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Миротворци;</li>
            <li>Дълбока връзка с обкръжаващия свят, фино възприятие;</li>
            <li>Свободни от всичко, не чувстват рамки и ограничения;</li>
            <li>Толерантни, търпеливи към всички, обединяват хората;</li>
            <li>Обичат да пътуват и да изучават други култури;</li>
            <li>Лесно се преместват;</li>
            <li>
              Навсякъде се чувстват като у дома си, адаптират се бързо и лесно;
            </li>
            <li>Творчески, имат дарба за чужди езици;</li>
            <li>Дарба за красноречие.</li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Проявяват нетолерантност, нетърпимост и войнствено отношение;
            </li>
            <li>Те разделят хората по националност и други характеристики;</li>
            <li>Подклаждат конфликти;</li>
            <li>
              Изолират се от света, изпадат в самоограничение и липса на
              свобода;
            </li>
            <li>
              Те искат да получат всичко наведнъж, не могат да действат стъпка
              по стъпка, изпитват апатия, ако глобалното нещо не се получи
              веднага;
            </li>
            <li>Те не знаят как да гледат на живота реалистично;</li>
            <li>Приземеност, липса на духовно развитие.</li>
          </ul>
        </div>
        <div
          className={`energy-section ${firstP === 22 ? "" : "hidden"}`}
          id="22"
        >
          <strong className="energy-title">ДВАДЕСЕТ И ВТОРА ЕНЕРГИЯ</strong>
          <br></br>
          <br></br>
          <strong className="energy-section-title">Положителни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>Вечните деца, в които се крие голяма мъдрост;</li>
            <li>Отворени към света, непринудени, приключенски настроени;</li>
            <li>Позитивни, артистични и талантливи хора;</li>
            <li>
              Имат дарба в гласа си, трябва да стоят на сцената, да вдъхновяват
              другите;
            </li>
            <li>Свободни от ограничения, рамки и забрани;</li>
            <li>
              Те възприемат живота като приключение и не се страхуват да отидат
              в новото и непознатото.
            </li>
          </ul>
          <strong className="energy-section-title">Отрицателни прояви</strong>
          <br></br>
          <ul className="energy-list">
            <li>
              Инфантилност, детско отношение, нежелание за поемане на
              отговорност;
            </li>
            <li>Разгулен живот, влизат в зависимости;</li>
            <li>
              Пристрастяване към материални неща, рамки и ограничения, страх от
              навлизане в нещо ново;
            </li>
            <li>Ограничено мислене, липса на духовно развитие;</li>
            <li>
              Липса на лекота, позитивизъм, вдъхновение, творческа реализация;
            </li>
            <li>
              Контрол над близките, лишаване от свободата им, потискане на
              волята им.
            </li>
          </ul>
        </div>
      </div>
      <div className="tab-content" id="tab2">
        <div className="tab-in">
          <h2 className="h2" style={{ fontSize: "1.6em", fontWeight: "bold" }}>
            Сфера Личност
          </h2>
          <img
            src="https://numschool-web.ru/wp-content/uploads/2024/07/w23231-1.svg"
            className="aaa"
            alt="Tab 2 Image"
            style={{ width: "80%" }}
          />
          <div
            className="star-point star-point5"
            style={{ left: "41.5%", top: "19%" }}
          >
            {firstP}
          </div>
          <div
            className="star-point star-point22 st22"
            style={{ left: "12%", top: "52%" }}
          >
            {tenthSemi}
          </div>
          <div
            className="star-point star-point7"
            style={{ left: "72%", top: "52%" }}
          >
            {firstSemi}
          </div>
          <div
            className="star-point star-point19 st19"
            style={{ left: "-10%", top: "80%" }}
          >
            {fifthGap}
          </div>
          <div
            className="star-point star-point8"
            style={{ left: "92%", top: "80%" }}
          >
            {firstGap}
          </div>
          <div
            className="star-point star-point12"
            style={{ left: "41.5%", top: "84%" }}
          >
            {reduceTo22(fifthGap + firstGap)}
          </div>
          <div
            className="star-point star-point28"
            style={{ left: "32%", top: "49%" }}
          >
            {reduceTo22(tenthSemi + firstSemi)}
          </div>
          <div
            className="star-point star-point29"
            style={{ left: "52%", top: "49%" }}
          >
            {reduceTo22(firstP + reduceTo22(fifthGap + firstGap))}
          </div>
        </div>
        <div className="tab-in">
          <h2 className="h2" style={{ fontSize: "1.6em", fontWeight: "bold" }}>
            Сфера Духовност
          </h2>
          <img
            src="https://numschool-web.ru/wp-content/uploads/2024/07/w23231-1.svg"
            className="aaa"
            alt="Tab 3 Image"
            style={{ width: "80%" }}
          />
          <div
            className="star-point star-point1"
            style={{ left: "41.5%", top: "19%" }}
          >
            {secondP}
          </div>
          <div
            className="star-point star-point18"
            style={{ left: "12%", top: "52%" }}
          >
            {secondSemi}
          </div>
          <div
            className="star-point star-point17 st17"
            style={{ left: "72%", top: "52%" }}
          >
            {thirdSemi}
          </div>
          <div
            className="star-point star-point8 st8"
            style={{ left: "-10%", top: "80%" }}
          >
            {firstGap}
          </div>
          <div
            className="star-point star-point101 st10"
            style={{ left: "92%", top: "80%" }}
          >
            {secondGap}
          </div>
          <div
            className="star-point star-point9"
            style={{ left: "41.5%", top: "84%" }}
          >
            {reduceTo22(firstGap + secondGap)}
          </div>
          <div
            className="star-point star-point30"
            style={{ left: "32%", top: "49%" }}
          >
            {reduceTo22(secondSemi + thirdSemi)}
          </div>
          <div
            className="star-point star-point31"
            style={{ left: "52%", top: "49%" }}
          >
            {reduceTo22(secondP + reduceTo22(firstGap + secondGap))}
          </div>
        </div>
        <div className="tab-in">
          <h2 className="h2" style={{ fontSize: "1.6em", fontWeight: "bold" }}>
            Сфера Реализация
          </h2>
          <img
            src="https://numschool-web.ru/wp-content/uploads/2024/07/w23231-1.svg"
            className="aaa"
            alt="Tab 4 Image"
            style={{ width: "80%" }}
          />
          <div
            className="star-point star-point2"
            style={{ left: "41.5%", top: "19%" }}
          >
            {thirdP}
          </div>
          <div
            className="star-point star-point11"
            style={{ left: "12%", top: "52%" }}
          >
            {fourthSemi}
          </div>
          <div
            className="star-point star-point27"
            style={{ left: "72%", top: "52%" }}
          >
            {fifthSemi}
          </div>
          <div
            className="star-point star-point10"
            style={{ left: "-10%", top: "80%" }}
          >
            {secondGap}
          </div>
          <div
            className="star-point star-point20"
            style={{ left: "92%", top: "80%" }}
          >
            {thirdGap}
          </div>
          <div
            className="star-point star-point14"
            style={{ left: "41.5%", top: "84%" }}
          >
            {reduceTo22(secondGap + thirdGap)}
          </div>
          <div
            className="star-point star-point32"
            style={{ left: "32%", top: "49%" }}
          >
            {reduceTo22(fourthSemi + fifthSemi)}
          </div>
          <div
            className="star-point star-point33"
            style={{ left: "52%", top: "49%" }}
          >
            {reduceTo22(thirdP + reduceTo22(secondGap + thirdGap))}
          </div>
        </div>

        <div className="tab-in">
          <h2 className="h2" style={{ fontSize: "1.6em", fontWeight: "bold" }}>
            Сфера Отношения
          </h2>
          <img
            src="https://numschool-web.ru/wp-content/uploads/2024/07/w23231-1.svg"
            className="aaa"
            alt="Tab 5 Image"
            style={{ width: "80%" }}
          />
          <div
            className="star-point star-point3"
            style={{ left: "42%", top: "19%" }}
          >
            {fourthP}
          </div>
          <div
            className="star-point star-point266 st26"
            style={{ left: "10%", top: "52%" }}
          >
            {sixthSemi}
          </div>
          <div
            className="star-point star-point25"
            style={{ left: "72%", top: "52%" }}
          >
            {seventhSemi}
          </div>
          <div
            className="star-point star-point200 st20"
            style={{ left: "-10%", top: "80%" }}
          >
            {thirdGap}
          </div>
          <div
            className="star-point star-point211 st21"
            style={{ left: "95%", top: "80%" }}
          >
            {fourthGap}
          </div>
          <div
            className="star-point star-point16"
            style={{ left: "41.5%", top: "84%" }}
          >
            {reduceTo22(thirdGap + fourthGap)}
          </div>
          <div
            className="star-point star-point34"
            style={{ left: "32%", top: "49%" }}
          >
            {reduceTo22(sixthSemi + seventhSemi)}
          </div>
          <div
            className="star-point star-point35"
            style={{ left: "51%", top: "49%" }}
          >
            {reduceTo22(fourthP + reduceTo22(thirdGap + fourthGap))}
          </div>
        </div>

        <div className="tab-in">
          <h2 className="h2" style={{ fontSize: "1.6em", fontWeight: "bold" }}>
            Сфера Здраве
          </h2>
          <img
            src="https://numschool-web.ru/wp-content/uploads/2024/07/w23231-1.svg"
            className="aaa"
            alt="Tab 6 Image"
            style={{ width: "80%" }}
          />
          <div
            className="star-point star-point4"
            style={{ left: "41.5%", top: "19%" }}
          >
            {fifthP}
          </div>
          <div
            className="star-point star-point24"
            style={{ left: "12%", top: "52%" }}
          >
            {eighthSemi}
          </div>
          <div
            className="star-point star-point23"
            style={{ left: "72%", top: "52%" }}
          >
            {ninethSemi}
          </div>
          <div
            className="star-point star-point21 st211"
            style={{ left: "-10%", top: "80%" }}
          >
            {fourthGap}
          </div>
          <div
            className="star-point star-point194 st199"
            style={{ left: "92%", top: "80%" }}
          >
            {fifthGap}
          </div>
          <div
            className="star-point star-point15"
            style={{ left: "41.5%", top: "84%" }}
          >
            {reduceTo22(fourthGap + fifthGap)}
          </div>
          <div
            className="star-point star-point36"
            style={{ left: "32%", top: "49%" }}
          >
            {reduceTo22(eighthSemi + ninethSemi)}
          </div>
          <div
            className="star-point star-point37"
            style={{ left: "52%", top: "49%" }}
          >
            {reduceTo22(fifthP + reduceTo22(fourthGap + fifthGap))}
          </div>
        </div>
      </div>
      <div className="tab-content" id="tab3">
        <div className="tab-in" id="tab3">
          <h2 className="h2" style={{ fontWeight: "bold", fontSize: "120%" }}>
            Фатална грешка
          </h2>
          <img
            src="https://numschool-web.ru/zvezda/48097222.svg"
            className="aaa"
            alt="Tab 7 Image"
          />
          <div
            className="star-point star-point21"
            style={{ top: "90%", left: "43.9%" }}
          >
            {fourthGap}
          </div>
        </div>

        <div className="tab-in" id="tab3">
          <h2 className="h2" style={{ fontWeight: "bold", fontSize: "120%" }}>
            Чакри
          </h2>
          <img
            src="https://numschool-web.ru/zvezda/48097222.svg"
            className="aaa"
            alt="Tab 8 Image"
          />
          <div
            className="star-point star-point5"
            style={{
              left: "-8%",
              width: "5vmin",
              height: "5vmin",
              top: "57%",
              fontSize: "2vmin",
              background: "#C611DB",
              outline: "0.7vmin solid rgba(198, 17, 219, 0.5)",
              color: "white",
            }}
          >
            {firstP}
          </div>
          <div
            className="star-point star-point7"
            style={{ left: "12.8%", top: "56%" }}
          >
            {firstSemi}
          </div>
          <div
            className="star-point star-point8"
            style={{ left: "28%", top: "56%" }}
          >
            {firstGap}
          </div>
          <div
            className="star-point star-point9"
            style={{ left: "43.2%", top: "56%" }}
          >
            {reduceTo22(firstGap + secondGap)}
          </div>
          <div
            className="star-point star-point10"
            style={{ left: "58.4%", top: "56%" }}
          >
            {secondGap}
          </div>
          <div
            className="star-point star-point11"
            style={{ left: "74%", top: "56%" }}
          >
            {fourthSemi}
          </div>
          <div
            className="star-point star-point2"
            style={{
              left: "95%",
              width: "5vmin",
              height: "5vmin",
              top: "57%",
              fontSize: "2vmin",
              background: "rgba(220, 2, 39, 1)",
              outline: "0.7vmin solid rgba(220, 2, 39, 0.5)",
              color: "white",
            }}
          >
            {thirdP}
          </div>
        </div>

        <div className="tab-in" id="tab3">
          <h2 className="h2" style={{ fontWeight: "bold", fontSize: "120%" }}>
            Код на душата
          </h2>
          <img
            src="https://numschool-web.ru/zvezda/48097222.svg"
            className="aaa"
            alt="Tab 9 Image"
          />
          <div
            className="star-point star-point-new1"
            style={{
              left: "35%",
              top: "68%",
              width: "3vmin",
              fontSize: "1.6vmin",
              height: "3vmin",
              background:
                "linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%)",
              outline: "0.5vmin solid rgba(132, 0, 22, 0.5)",
              color: "white",
            }}
          >
            {reduceTo22(firstP + secondP + thirdP + fourthP + fifthP)}
          </div>
          <div
            className="star-point star-point-new2"
            style={{
              left: "44%",
              top: "68%",
              width: "3vmin",
              fontSize: "1.6vmin",
              height: "3vmin",
              background:
                "linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%)",
              outline: "0.5vmin solid rgba(132, 0, 22, 0.5)",
              color: "white",
            }}
          >
            {reduceTo22(firstGap + secondGap + thirdGap + fourthGap + fifthGap)}
          </div>
          <div
            className="star-point star-point-new3"
            style={{
              left: "53.8%",
              top: "68%",
              width: "3vmin",
              fontSize: "1.6vmin",
              height: "3vmin",
              background:
                "linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%)",
              outline: "0.5vmin solid rgba(132, 0, 22, 0.5)",
              color: "white",
            }}
          >
            {reduceTo22(
              reduceTo22(
                firstGap + secondGap + thirdGap + fourthGap + fifthGap
              ) + reduceTo22(firstP + secondP + thirdP + fourthP + fifthP)
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
