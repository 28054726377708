import { useEffect, useState } from "react";
import Star from "./components/Star";
import Energies from "./components/Energies";

function App() {
  const [date, setDate] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [showStar, setShowStar] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    console.log(date);
    // Split the date into day, month, and year
    if (date.length === 10) {
      const [d, m, y] = date.split(".");
      setDay(d);
      setMonth(m);
      setYear(y);
      console.log("Day:", d, "Month:", m, "Year:", y); // Log the values for debugging
    }
  }, [date]);

  // Function to format date input as DD.MM.YYYY
  const formatDate = (value) => {
    // Remove any non-digit characters
    const cleanedValue = value.replace(/\D/g, "");

    // Format the cleaned value
    let formattedDate = cleanedValue;

    if (cleanedValue.length > 2) {
      formattedDate = `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(2)}`;
    }
    if (cleanedValue.length > 4) {
      formattedDate = `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(2, 4)}.${cleanedValue.slice(4)}`;
    }

    // Limit the length to DD.MM.YYYY (10 characters)
    if (formattedDate.length > 10) {
      formattedDate = formattedDate.slice(0, 10);
    }

    return formattedDate;
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDate(value);
    setDate(formattedValue);
  };

  const handleShowStar = () => {
    setShowStar(true);
    setShowInput(false);
  };

  return (
    <div className={`bg-white flex items-center justify-center ${showStar ? '' : 'h-[100vh]'}`}>
      <div>
        {showInput && (
          <label className="block">
            <input
              className="block outline-none text-center p-4 bg-gray-100"
              style={{ fontSize: "28px", height: "168px", width: "372px", borderRadius: "8px", textDecoration: "underline", textUnderlineOffset: "6px", fontWeight: "bold" }}
              type="text"
              placeholder="ДД.ММ.ГГГГ"
              value={date}
              onChange={handleDateChange}
              maxLength={10} // Limit input length to match DD.MM.YYYY
            />
          </label>
        )}
        {showInput && date && date.length === 10 && (
          <button
            className="bg-gray-200 rounded-[5px] text-center mt-[10px] py-2 w-full text-lg"
            onClick={handleShowStar}
          >
            Изчисли
          </button>
        )}
        {showStar && <Star day={day} month={month} year={year}></Star>}
        {showStar && <Energies day={day} month={month} year={year}></Energies>}
      </div>
    </div>
  );
}

export default App;
