import React from "react";
import "./Star.css";

const Star = ({day, month, year}) => {

  const reduceTo22 = (num) => {
    // Only reduce the number if it is greater than 22
    if (num <= 22) {
        return num;
    }
    while (num > 22) {
        num = num.toString().split('').reduce((sum, digit) => sum + parseInt(digit), 0);
    }
    return num;
};

  const firstP = Number(reduceTo22(day));
  const secondP = Number(reduceTo22(month));
  const thirdP = Number(reduceTo22(year));
  const fourthP = reduceTo22(firstP + secondP + thirdP);
  const fifthP = reduceTo22(firstP + secondP + thirdP + fourthP);
  const sixthP = reduceTo22(firstP + secondP + thirdP + fourthP + fifthP);

  const firstGap = reduceTo22(firstP + secondP);
  const secondGap = reduceTo22(secondP + thirdP);
  const thirdGap = reduceTo22(thirdP + fourthP);
  const fourthGap = reduceTo22(fourthP + fifthP);
  const fifthGap = reduceTo22(fifthP + firstP);

  const firstSemi = reduceTo22(firstP + firstGap);
  const secondSemi = reduceTo22(firstGap + secondP);
  const thirdSemi = reduceTo22(secondP + secondGap);
  const fourthSemi = reduceTo22(secondGap + thirdP);
  const fifthSemi = reduceTo22(thirdP + thirdGap);
  const sixthSemi = reduceTo22(thirdGap + fourthP);
  const seventhSemi = reduceTo22(fourthP + fourthGap);
  const eighthSemi = reduceTo22(fourthGap + fifthP);
  const ninethSemi = reduceTo22(fifthP + fifthGap);
  const tenthSemi = reduceTo22(fifthGap + firstP);

  return (
    <div className="tabcont">
      <div className="tab-content active" id="tab1">
        <div className="circle-container">
          <div className="circle" id="circle">
            <img src="https://numschool-web.ru/zvezda/234234222223.svg" alt="Center Image" className="center-image"/>
            <div className="star-point star-point1" style={{ left: "46.6%", top: "12%" }}>{secondP}</div>
            <div className="star-point star-point2" style={{ left: "82%", top: "39%" }}>{thirdP}</div>
            <div className="star-point star-point3" style={{ left: "66%", top: "76%" }}>{fourthP}</div>
            <div className="star-point star-point4" style={{ left: "26%", top: "76%" }}>{fifthP}</div>
            <div className="star-point star-point5" style={{ left: "12%", top: "39%" }}>{firstP}</div>
            <div className="star-point star-point6" style={{ left: "46.6%", top: "50%" }}>{sixthP}</div>
            <div className="star-point star-point7" style={{ left: "28%", top: "38%" }}>{firstSemi}</div>
            <div className="star-point star-point8" style={{ left: "37.6%", top: "38%" }}>{firstGap}</div>
            <div className="star-point star-point9" style={{ left: "46.6%", top: "38%" }}>{reduceTo22(firstGap + secondGap)}</div>
            <div className="star-point star-point10" style={{ left: "55.6%", top: "38%" }}>{secondGap}</div>
            <div className="star-point star-point11" style={{ left: "65%", top: "38%" }}>{fourthSemi}</div>
            <div className="star-point star-point12" 
            style={{ left: "41%", top: "49.5%", width: "3.5vmin", height: "3.5vmin", fontSize: "2vmin" }}>{reduceTo22(firstGap + fifthGap)}</div>
            <div className="star-point star-point13" 
            style={{ left: "48%", top: "44%", width: "3.5vmin", height: "3.5vmin", fontSize: "2vmin" }}>{reduceTo22(firstGap + secondGap)}</div>
            <div className="star-point star-point14" 
            style={{ left: "55%", top: "49.5%", width: "3.5vmin", height: "3.5vmin", fontSize: "2vmin" }}>{reduceTo22(secondGap + thirdGap)}</div>
            <div className="star-point star-point15" style={{ left: "43%", top: "56.5%", width: "3.5vmin", height: "3.5vmin", fontSize: "2vmin" }}>{reduceTo22(fifthGap + fourthGap)}</div>
            <div className="star-point star-point16" 
            style={{ left: "53%", top: "56.5%", width: "3.5vmin", height: "3.5vmin", fontSize: "2vmin" }}>{reduceTo22(fourthGap + thirdGap)}</div>
            <div className="star-point star-point17" 
            style={{ left: "39%", top: "30%", width: "4vmin", height: "4vmin", fontSize: "2vmin" }}>{secondSemi}</div>
            <div className="star-point star-point18" 
            style={{ left: "56.5%", top: "30%", width: "4vmin", height: "4vmin", fontSize: "2vmin" }}>{thirdSemi}</div>
            <div className="star-point star-point19" 
            style={{ left: "30%", top: "57%", width: "4vmin", height: "4vmin", fontSize: "2vmin" }}>{fifthGap}</div>
            <div className="star-point star-point20" 
            style={{ left: "65%", top: "57%", width: "4vmin", height: "4vmin", fontSize: "2vmin" }}>{thirdGap}</div>
            <div className="star-point star-point21" 
            style={{ left: "47.5%", top: "68%", width: "4vmin", height: "4vmin", fontSize: "2vmin" }}>{fourthGap}</div>
            <div className="star-point star-point22" 
            style={{ left: "25%", top: "53%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{tenthSemi}</div>
            <div className="star-point star-point23" 
            style={{ left: "28%", top: "65%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{ninethSemi}</div>
            <div className="star-point star-point24" 
            style={{ left: "40%", top: "74%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{eighthSemi}</div>
            <div className="star-point star-point25" 
            style={{ left: "56%", top: "74%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{seventhSemi}</div>
            <div className="star-point star-point26" 
            style={{ left: "68%", top: "65%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{sixthSemi}</div>
            <div className="star-point star-point27" 
            style={{ left: "72%", top: "53%", width: "3vmin", height: "3vmin", fontSize: "2vmin" }}>{fifthSemi}</div>
            <div>
              <>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(-0.0620631vmin)",
                    top: "calc(38.2372vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "-5vmin", top: "38vmin" }}
                >
                  0/100 ГОД.
                </div>
                <div
                  className="number large"
                  id={0}
                  style={{ left: "4.39901vmin", top: "39.2939vmin" }}
                >
                  {firstP}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(1.07293vmin)", top: "calc(34.561vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "-0.748825vmin", top: "34.2276vmin" }}
                >
                  1-2
                </div>
                <div
                  className="number small"
                  id={1}
                  style={{ left: "3.14469vmin", top: "35.1444vmin" }}
                >
                  {reduceTo22(firstP + reduceTo22(firstP + firstSemi))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(2.20044vmin)",
                    top: "calc(30.5963vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "0.428788vmin", top: "30.0867vmin" }}
                >
                  2-3
                </div>
                <div
                  className="number small"
                  id={2}
                  style={{ left: "4.22208vmin", top: "31.3559vmin" }}
                >
                  {reduceTo22(firstP + firstSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(3.68598vmin)",
                    top: "calc(26.7514vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "1.98036vmin", top: "26.0709vmin" }}
                >
                  3-4
                </div>
                <div
                  className="number small"
                  id={3}
                  style={{ left: "5.64161vmin", top: "27.6819vmin" }}
                >
                  {reduceTo22(reduceTo22(firstP + firstSemi) + firstSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(4.40471vmin)",
                    top: "calc(24.0704vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "1.34068vmin", top: "22.7545vmin" }}
                >
                  5 ГОД.
                </div>
                <div
                  className="number large"
                  id={5}
                  style={{ left: "8.46873vmin", top: "26.3864vmin" }}
                >
                  {reduceTo22(firstP + firstGap )}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(6.81725vmin)",
                    top: "calc(20.8623vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "5.25079vmin", top: "19.9201vmin" }}
                >
                  6-7
                </div>
                <div
                  className="number small"
                  id={6}
                  style={{ left: "8.6337vmin", top: "22.0546vmin" }}
                >
                  {reduceTo22(firstSemi + reduceTo22(firstSemi + firstGap))}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(9.1741vmin)", top: "calc(17.4807vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "7.71239vmin", top: "16.3882vmin" }}
                >
                  7-8
                </div>
                <div
                  className="number small"
                  id={7}
                  style={{ left: "10.8858vmin", top: "18.8233vmin" }}
                >
                  {reduceTo22(firstSemi + firstGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(11.8305vmin)", top: "calc(14.329vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "10.4868vmin", top: "13.0964vmin" }}
                >
                  8-9
                </div>
                <div
                  className="number small"
                  id={8}
                  style={{ left: "13.4241vmin", top: "15.8116vmin" }}
                >
                  {reduceTo22(reduceTo22(firstSemi + firstGap) + firstGap)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(14.3891vmin)",
                    top: "calc(11.0585vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "12.2126vmin", top: "8.5859vmin" }}
                >
                  10 ГОД.
                </div>
                <div
                  className="number large"
                  id={10}
                  style={{ left: "17.5656vmin", top: "14.5311vmin" }}
                >
                  {firstGap}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(17.9504vmin)",
                    top: "calc(8.81858vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "16.8787vmin", top: "7.34107vmin" }}
                >
                  11-12
                </div>
                <div
                  className="number small"
                  id={11}
                  style={{ left: "19.272vmin", top: "10.5461vmin" }}
                >
                  {reduceTo22(reduceTo22(secondSemi + firstGap) + firstGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(21.3626vmin)",
                    top: "calc(6.50619vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "20.4426vmin", top: "4.92591vmin" }}
                >
                  12-13
                </div>
                <div
                  className="number small"
                  id={12}
                  style={{ left: "22.5326vmin", top: "8.33647vmin" }}
                >
                  {reduceTo22(firstGap + secondSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(24.972vmin)", top: "calc(4.51573vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "24.2124vmin", top: "2.84698vmin" }}
                >
                  13-14
                </div>
                <div
                  className="number small"
                  id={13}
                  style={{ left: "25.9816vmin", top: "6.43447vmin" }}
                >
                  {reduceTo22(reduceTo22(secondSemi + firstGap) + secondSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(28.3734vmin)",
                    top: "calc(2.48888vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "27.44vmin", top: "-0.745441vmin" }}
                >
                  15 ГОД.
                </div>
                <div
                  className="number large"
                  id={15}
                  style={{ left: "30.3069vmin", top: "6.7232vmin" }}
                >
                  {reduceTo22(firstGap + secondP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(32.6602vmin)",
                    top: "calc(1.56451vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "32.2423vmin", top: "-0.235396vmin" }}
                >
                  16-17
                </div>
                <div
                  className="number small"
                  id={16}
                  style={{ left: "33.3281vmin", top: "3.61442vmin" }}
                >
                  {reduceTo22(secondSemi + reduceTo22(secondSemi + secondP))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(36.6744vmin)",
                    top: "calc(0.628529vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "36.4349vmin", top: "-1.21298vmin" }}
                >
                  17-18
                </div>
                <div
                  className="number small"
                  id={17}
                  style={{ left: "37.1639vmin", top: "2.72004vmin" }}
                >
                  {reduceTo22(secondSemi + secondP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(40.7574vmin)",
                    top: "calc(0.0637783vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "40.6994vmin", top: "-1.80283vmin" }}
                >
                  18-19
                </div>
                <div
                  className="number small"
                  id={18}
                  style={{ left: "41.0654vmin", top: "2.18039vmin" }}
                >
                  {reduceTo22(reduceTo22(secondSemi + secondP) + secondP)}
                </div>
                <div
                  className="outer-point large"
                  style={{ left: "calc(44.5vmin)", top: "calc(-0.5vmin)" }}
                />
                <div
                  className="label large"
                  style={{ left: "45vmin", top: "-4vmin" }}
                >
                  20 ГОД.
                </div>
                <div
                  className="number large"
                  id={20}
                  style={{ left: "45vmin", top: "4vmin" }}
                >
                  {secondP}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(48.9926vmin)",
                    top: "calc(0.0637783vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "49.3006vmin", top: "-1.80283vmin" }}
                >
                  21-22
                </div>
                <div
                  className="number small"
                  id={21}
                  style={{ left: "48.9346vmin", top: "2.18039vmin" }}
                >
                  {reduceTo22(secondP + reduceTo22(secondP + thirdSemi))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(53.0756vmin)",
                    top: "calc(0.628529vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "53.5651vmin", top: "-1.21298vmin" }}
                >
                  22-23
                </div>
                <div
                  className="number small"
                  id={22}
                  style={{ left: "52.8361vmin", top: "2.72004vmin" }}
                >
                  {reduceTo22(secondP + thirdSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(57.0898vmin)",
                    top: "calc(1.56451vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "57.7577vmin", top: "-0.235396vmin" }}
                >
                  23-24
                </div>
                <div
                  className="number small"
                  id={23}
                  style={{ left: "56.6719vmin", top: "3.61442vmin" }}
                >
                  {reduceTo22(reduceTo22(secondP + thirdSemi) + thirdSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(60.6266vmin)",
                    top: "calc(2.48888vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "62.56vmin", top: "-0.745441vmin" }}
                >
                  25 ГОД.
                </div>
                <div
                  className="number large"
                  id={25}
                  style={{ left: "59.6931vmin", top: "6.7232vmin" }}
                >
                  {reduceTo22(secondP + secondGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(64.778vmin)", top: "calc(4.51573vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "65.7876vmin", top: "2.84698vmin" }}
                >
                  26-27
                </div>
                <div
                  className="number small"
                  id={26}
                  style={{ left: "64.0184vmin", top: "6.43447vmin" }}
                >
                  {reduceTo22(thirdSemi + reduceTo22(thirdSemi + secondGap))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(68.3874vmin)",
                    top: "calc(6.50619vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "69.5574vmin", top: "4.92591vmin" }}
                >
                  27-28
                </div>
                <div
                  className="number small"
                  id={27}
                  style={{ left: "67.4674vmin", top: "8.33647vmin" }}
                >
                  {reduceTo22(thirdSemi + secondGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(71.7996vmin)",
                    top: "calc(8.81858vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "73.1213vmin", top: "7.34107vmin" }}
                >
                  28-29
                </div>
                <div
                  className="number small"
                  id={28}
                  style={{ left: "70.728vmin", top: "10.5461vmin" }}
                >
                  {reduceTo22(reduceTo22(thirdSemi + secondGap) + secondGap)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(74.6109vmin)",
                    top: "calc(11.0585vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "77.7874vmin", top: "8.5859vmin" }}
                >
                  30 ГОД.
                </div>
                <div
                  className="number large"
                  id={30}
                  style={{ left: "72.4344vmin", top: "14.5311vmin" }}
                >
                  {secondGap}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(77.6517vmin)",
                    top: "calc(14.0418vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "79.2334vmin", top: "12.7964vmin" }}
                >
                  31-32
                </div>
                <div
                  className="number small"
                  id={31}
                  style={{ left: "76.32vmin", top: "15.5371vmin" }}
                >
                  {reduceTo22(reduceTo22(fourthSemi + secondGap) + secondGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(80.0924vmin)",
                    top: "calc(16.8618vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "81.7826vmin", top: "15.7418vmin" }}
                >
                  32-33
                </div>
                <div
                  className="number small"
                  id={32}
                  style={{ left: "78.6522vmin", top: "18.2319vmin" }}
                >
                  {reduceTo22(secondGap + fourthSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(82.2911vmin)",
                    top: "calc(19.8743vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "84.0791vmin", top: "18.8882vmin" }}
                >
                  33-34
                </div>
                <div
                  className="number small"
                  id={33}
                  style={{ left: "80.7532vmin", top: "21.1105vmin" }}
                >
                  {reduceTo22(reduceTo22(fourthSemi + secondGap) + fourthSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(84.5953vmin)",
                    top: "calc(24.0704vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "88.6593vmin", top: "22.7545vmin" }}
                >
                  35 ГОД.
                </div>
                <div
                  className="number large"
                  id={35}
                  style={{ left: "81.5313vmin", top: "26.3864vmin" }}
                >
                  {reduceTo22(secondGap + thirdP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(86.2206vmin)",
                    top: "calc(27.1115vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "88.1832vmin", top: "26.447vmin" }}
                >
                  36-37
                </div>
                <div
                  className="number small"
                  id={36}
                  style={{ left: "84.508vmin", top: "28.026vmin" }}
                >
                  {reduceTo22(fourthSemi + reduceTo22(fourthSemi + thirdP))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(87.2939vmin)",
                    top: "calc(29.8537vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "89.3042vmin", top: "29.3111vmin" }}
                >
                  37-38
                </div>
                <div
                  className="number small"
                  id={37}
                  style={{ left: "85.5336vmin", top: "30.6463vmin" }}
                >
                  {reduceTo22(fourthSemi + thirdP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(88.1855vmin)",
                    top: "calc(32.6602vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "90.2354vmin", top: "32.2423vmin" }}
                >
                  38-39
                </div>
                <div
                  className="number small"
                  id={38}
                  style={{ left: "86.3856vmin", top: "33.3281vmin" }}
                >
                 {reduceTo22(reduceTo22(fourthSemi + thirdP) + thirdP)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(89.0621vmin)",
                    top: "calc(38.2372vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "93.5231vmin", top: "38.1805vmin" }}
                >
                  40 ГОД.
                </div>
                <div
                  className="number large"
                  id={40}
                  style={{ left: "85.601vmin", top: "39.2939vmin" }}
                >
                  {thirdP}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(89.7513vmin)",
                    top: "calc(41.5401vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "91.8708vmin", top: "41.5169vmin" }}
                >
                  41-42
                </div>
                <div
                  className="number small"
                  id={41}
                  style={{ left: "87.8818vmin", top: "41.8133vmin" }}
                >
                  {reduceTo22(thirdP + reduceTo22(thirdP + fifthSemi))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(89.8733vmin)",
                    top: "calc(44.4823vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "91.9982vmin", top: "44.5899vmin" }}
                >
                  42-43
                </div>
                <div
                  className="number small"
                  id={42}
                  style={{ left: "87.9984vmin", top: "44.6248vmin" }}
                >
                  {reduceTo22(thirdP + fifthSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(89.8026vmin)",
                    top: "calc(47.4262vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "91.9244vmin", top: "47.6646vmin" }}
                >
                  43-44
                </div>
                <div
                  className="number small"
                  id={43}
                  style={{ left: "87.9308vmin", top: "47.4378vmin" }}
                >
                  {reduceTo22(reduceTo22(thirdP + fifthSemi) + fifthSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(89.1646vmin)",
                    top: "calc(49.9841vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "93.6348vmin", top: "50.9716vmin" }}
                >
                  45 ГОД.
                </div>
                <div
                  className="number large"
                  id={45}
                  style={{ left: "85.6944vmin", top: "49.9966vmin" }}
                >
                  {reduceTo22(thirdP + thirdGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(89.0853vmin)",
                    top: "calc(53.2686vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "91.1752vmin", top: "53.7666vmin" }}
                >
                  46-47
                </div>
                <div
                  className="number small"
                  id={46}
                  style={{ left: "87.2454vmin", top: "53.0205vmin" }}
                >
                  {reduceTo22(fifthSemi + reduceTo22(fifthSemi + thirdGap))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(88.4416vmin)",
                    top: "calc(56.1421vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "90.5029vmin", top: "56.7679vmin" }}
                >
                  47-48
                </div>
                <div
                  className="number small"
                  id={47}
                  style={{ left: "86.6303vmin", top: "55.7663vmin" }}
                >
                  {reduceTo22(fifthSemi + thirdGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(87.6115vmin)",
                    top: "calc(58.9674vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "89.6359vmin", top: "59.7187vmin" }}
                >
                  48-49
                </div>
                <div
                  className="number small"
                  id={48}
                  style={{ left: "85.8371vmin", top: "58.466vmin" }}
                >
                  {reduceTo22(reduceTo22(fifthSemi + thirdGap) + thirdGap)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(86.2233vmin)",
                    top: "calc(61.3573vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "90.432vmin", top: "63.3557vmin" }}
                >
                  50 ГОД.
                </div>
                <div
                  className="number large"
                  id={50}
                  style={{ left: "83.0145vmin", top: "60.3589vmin" }}
                >
                  {thirdGap}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(85.0591vmin)",
                    top: "calc(64.1294vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "86.97vmin", top: "65.1546vmin" }}
                >
                  51-52
                </div>
                <div
                  className="number small"
                  id={51}
                  style={{ left: "83.3981vmin", top: "63.3542vmin" }}
                >
                  {reduceTo22(reduceTo22(sixthSemi + thirdGap) + thirdGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(83.2438vmin)",
                    top: "calc(67.3874vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "85.0741vmin", top: "68.5574vmin" }}
                >
                  52-53
                </div>
                <div
                  className="number small"
                  id={52}
                  style={{ left: "81.6635vmin", top: "66.4674vmin" }}
                >
                  {reduceTo22(thirdGap + sixthSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(81.165vmin)", top: "calc(70.4839vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "83.9029vmin", top: "71.7916vmin" }}
                >
                  53-54
                </div>
                <div
                  className="number small"
                  id={53}
                  style={{ left: "79.6771vmin", top: "69.4263vmin" }}
                >
                  {reduceTo22(reduceTo22(sixthSemi + thirdGap) + sixthSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(80.4386vmin)",
                    top: "calc(71.5817vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "84.1331vmin", top: "74.4889vmin" }}
                >
                  55 ГОД.
                </div>
                <div
                  className="number large"
                  id={55}
                  style={{ left: "77.7441vmin", top: "69.6744vmin" }}
                >
                  {reduceTo22(thirdGap + fourthP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(78.8369vmin)",
                    top: "calc(74.3977vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "80.4714vmin", top: "75.8348vmin" }}
                >
                  56-57
                </div>
                <div
                  className="number small"
                  id={56}
                  style={{ left: "77.4525vmin", top: "73.2105vmin" }}
                >
                  {reduceTo22(sixthSemi + reduceTo22(sixthSemi + fourthP))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(76.6948vmin)",
                    top: "calc(76.6948vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "78.234vmin", top: "78.234vmin" }}
                >
                  57-58
                </div>
                <div
                  className="number small"
                  id={57}
                  style={{ left: "75.4056vmin", top: "75.4056vmin" }}
                >
                  {reduceTo22(sixthSemi + fourthP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(74.3977vmin)",
                    top: "calc(78.8369vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "75.8348vmin", top: "80.4714vmin" }}
                >
                  58-59
                </div>
                <div
                  className="number small"
                  id={58}
                  style={{ left: "73.2105vmin", top: "77.4525vmin" }}
                >
                   {reduceTo22(reduceTo22(sixthSemi + fourthP) + fourthP)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(69.6637vmin)",
                    top: "calc(81.8067vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "72.4005vmin", top: "85.6228vmin" }}
                >
                  60 ГОД.
                </div>
                <div
                  className="number large"
                  id={60}
                  style={{ left: "67.9269vmin", top: "78.9905vmin" }}
                >
                  {fourthP}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(67.0341vmin)", top: "calc(84.041vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "68.1439vmin", top: "85.9067vmin" }}
                >
                  61-62
                </div>
                <div
                  className="number small"
                  id={61}
                  style={{ left: "66.1742vmin", top: "82.4253vmin" }}
                >
                  {reduceTo22(fourthP + reduceTo22(fourthP + seventhSemi))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(63.8928vmin)",
                    top: "calc(85.6589vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "64.8631vmin", top: "87.5965vmin" }}
                >
                  62-63
                </div>
                <div
                  className="number small"
                  id={62}
                  style={{ left: "63.1726vmin", top: "83.9712vmin" }}
                >
                  {reduceTo22(fourthP + seventhSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(60.6343vmin)",
                    top: "calc(87.0252vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "61.4597vmin", top: "89.0236vmin" }}
                >
                  63-64
                </div>
                <div
                  className="number small"
                  id={63}
                  style={{ left: "60.0589vmin", top: "85.2769vmin" }}
                >
                  {reduceTo22(reduceTo22(fourthP + seventhSemi) + seventhSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(58.4058vmin)",
                    top: "calc(87.2975vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "60.1418vmin", top: "91.6018vmin" }}
                >
                  65 ГОД.
                </div>
                <div
                  className="number large"
                  id={65}
                  style={{ left: "57.6697vmin", top: "83.9933vmin" }}
                >
                  {reduceTo22(fourthP + fourthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(55.38vmin)", top: "calc(88.6316vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "55.9719vmin", top: "90.7014vmin" }}
                >
                  66-67
                </div>
                <div
                  className="number small"
                  id={66}
                  style={{ left: "55.0382vmin", top: "86.8119vmin" }}
                >
                  {reduceTo22(seventhSemi + reduceTo22(seventhSemi + fourthGap))}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(51.9146vmin)", top: "calc(89.321vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "52.3524vmin", top: "91.4214vmin" }}
                >
                  67-68
                </div>
                <div
                  className="number small"
                  id={67}
                  style={{ left: "51.7267vmin", top: "87.4706vmin" }}
                >
                  {reduceTo22(seventhSemi + fourthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(48.4057vmin)",
                    top: "calc(89.7363vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "48.6876vmin", top: "91.8551vmin" }}
                >
                  68-69
                </div>
                <div
                  className="number small"
                  id={68}
                  style={{ left: "48.3737vmin", top: "87.8674vmin" }}
                >
                  {reduceTo22(reduceTo22(seventhSemi + fourthGap) + fourthGap)}
                </div>
                <div
                  className="outer-point large"
                  style={{ left: "calc(44.5vmin)", top: "calc(89.5vmin)" }}
                />
                <div
                  className="label large"
                  style={{ left: "45vmin", top: "94vmin" }}
                >
                  70 ГОД.
                </div>
                <div
                  className="number large"
                  id={70}
                  style={{ left: "45vmin", top: "86vmin" }}
                >
                  {fourthGap}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(41.3443vmin)",
                    top: "calc(89.7363vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "41.3124vmin", top: "91.8551vmin" }}
                >
                  71-72
                </div>
                <div
                  className="number small"
                  id={71}
                  style={{ left: "41.6263vmin", top: "87.8674vmin" }}
                >
                  {reduceTo22(reduceTo22(eighthSemi + fourthGap) + fourthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(37.8354vmin)", top: "calc(89.321vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "37.6476vmin", top: "91.4214vmin" }}
                >
                  72-73
                </div>
                <div
                  className="number small"
                  id={72}
                  style={{ left: "38.2733vmin", top: "87.4706vmin" }}
                >
                  {reduceTo22(fourthGap + eighthSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(34.37vmin)", top: "calc(88.6316vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "34.0281vmin", top: "90.7014vmin" }}
                >
                  73-74
                </div>
                <div
                  className="number small"
                  id={73}
                  style={{ left: "34.9618vmin", top: "86.8119vmin" }}
                >
                  {reduceTo22(reduceTo22(eighthSemi + fourthGap) + eighthSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(30.5942vmin)",
                    top: "calc(87.2975vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "29.8582vmin", top: "91.6018vmin" }}
                >
                  75 ГОД.
                </div>
                <div
                  className="number large"
                  id={75}
                  style={{ left: "32.3303vmin", top: "83.9933vmin" }}
                >
                  {eighthSemi}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(28.1999vmin)",
                    top: "calc(86.6714vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "27.5838vmin", top: "88.654vmin" }}
                >
                  76-77
                </div>
                <div
                  className="number small"
                  id={76}
                  style={{ left: "29.066vmin", top: "84.9388vmin" }}
                >
                  {reduceTo22(eighthSemi + reduceTo22(eighthSemi + fifthP))}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(25.502vmin)", top: "calc(85.4913vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "24.766vmin", top: "87.4215vmin" }}
                >
                  77-78
                </div>
                <div
                  className="number small"
                  id={77}
                  style={{ left: "26.488vmin", top: "83.8112vmin" }}
                >
                  {reduceTo22(eighthSemi + fifthP)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(22.887vmin)", top: "calc(84.1373vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "22.0348vmin", top: "86.0073vmin" }}
                >
                  78-79
                </div>
                <div
                  className="number small"
                  id={78}
                  style={{ left: "23.9893vmin", top: "82.5173vmin" }}
                >
                  {reduceTo22(reduceTo22(eighthSemi + fifthP) + fifthP)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(18.0497vmin)",
                    top: "calc(80.9058vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "16.1985vmin", top: "84.6418vmin" }}
                >
                  80 ГОД.
                </div>
                <div
                  className="number large"
                  id={80}
                  style={{ left: "20.9008vmin", top: "78.1697vmin" }}
                >
                  {fifthP}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(16.1002vmin)",
                    top: "calc(79.4729vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "14.9464vmin", top: "81.1356vmin" }}
                >
                  81-82
                </div>
                <div
                  className="number small"
                  id={81}
                  style={{ left: "17.5041vmin", top: "78.0602vmin" }}
                >
                  {reduceTo22(fifthP + reduceTo22(fifthP + ninethSemi))}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(13.899vmin)", top: "calc(77.5168vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "12.6473vmin", top: "79.0926vmin" }}
                >
                  82-83
                </div>
                <div
                  className="number small"
                  id={82}
                  style={{ left: "15.4008vmin", top: "76.1911vmin" }}
                >
                  {reduceTo22(fifthP + ninethSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{ left: "calc(11.8305vmin)", top: "calc(75.421vmin)" }}
                />
                <div
                  className="label"
                  style={{ left: "10.4868vmin", top: "76.9036vmin" }}
                >
                  83-84
                </div>
                <div
                  className="number small"
                  id={83}
                  style={{ left: "13.4241vmin", top: "74.1884vmin" }}
                >
                  {reduceTo22(reduceTo22(fifthP + ninethSemi) + ninethSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(9.52843vmin)",
                    top: "calc(72.8194vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "6.91985vmin", top: "75.8367vmin" }}
                >
                  85 ГОД.
                </div>
                <div
                  className="number large"
                  id={85}
                  style={{ left: "13.137vmin", top: "70.8021vmin" }}
                >
                  {reduceTo22(fifthP + fifthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(8.12613vmin)",
                    top: "calc(70.8465vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "6.61785vmin", top: "72.1258vmin" }}
                >
                  86-87
                </div>
                <div
                  className="number small"
                  id={86}
                  style={{ left: "9.88441vmin", top: "69.8172vmin" }}
                >
                  {reduceTo22(ninethSemi + reduceTo22(ninethSemi + fifthGap))}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(6.50619vmin)",
                    top: "calc(68.3874vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "4.92591vmin", top: "69.5574vmin" }}
                >
                  87-88
                </div>
                <div
                  className="number small"
                  id={87}
                  style={{ left: "8.33647vmin", top: "67.4674vmin" }}
                >
                  {reduceTo22(ninethSemi + fifthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(5.05056vmin)",
                    top: "calc(65.8277vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "3.40558vmin", top: "66.8839vmin" }}
                >
                  88-89
                </div>
                <div
                  className="number small"
                  id={88}
                  style={{ left: "6.94553vmin", top: "65.0214vmin" }}
                >
                  {reduceTo22(reduceTo22(ninethSemi + fifthGap) + fifthGap)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(3.39045vmin)",
                    top: "calc(62.8031vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "0.236273vmin", top: "64.9301vmin" }}
                >
                  90 ГОД.
                </div>
                <div
                  className="number large"
                  id={90}
                  style={{ left: "7.54464vmin", top: "61.6762vmin" }}
                >
                  {fifthGap}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(2.52208vmin)",
                    top: "calc(60.0813vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "0.764727vmin", top: "60.8821vmin" }}
                >
                  91-92
                </div>
                <div
                  className="number small"
                  id={91}
                  style={{ left: "4.52943vmin", top: "59.5304vmin" }}
                >
                  {reduceTo22(reduceTo22(tenthSemi + fifthGap) + fifthGap)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(1.51163vmin)",
                    top: "calc(56.9007vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "-0.290631vmin", top: "57.5602vmin" }}
                >
                  92-93
                </div>
                <div
                  className="number small"
                  id={92}
                  style={{ left: "3.56389vmin", top: "56.4913vmin" }}
                >
                  {reduceTo22(fifthGap + tenthSemi)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(0.739662vmin)",
                    top: "calc(53.6541vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "-1.09691vmin", top: "54.1692vmin" }}
                >
                  93-94
                </div>
                <div
                  className="number small"
                  id={93}
                  style={{ left: "2.82623vmin", top: "53.3889vmin" }}
                >
                  {reduceTo22(reduceTo22(tenthSemi + fifthGap) + tenthSemi)}
                </div>
                <div
                  className="outer-point large"
                  style={{
                    left: "calc(0.0540247vmin)",
                    top: "calc(51.5396vmin)",
                  }}
                />
                <div
                  className="label large"
                  style={{ left: "-3.39673vmin", top: "52.6653vmin" }}
                >
                  95 ГОД.
                </div>
                <div
                  className="number large"
                  id={95}
                  style={{ left: "4.50478vmin", top: "51.4138vmin" }}
                >
                  {reduceTo22(fifthGap + firstP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(-0.00125833vmin)",
                    top: "calc(48.2099vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "-1.87076vmin", top: "48.4831vmin" }}
                >
                  96-97
                </div>
                <div
                  className="number small"
                  id={96}
                  style={{ left: "2.11824vmin", top: "48.1867vmin" }}
                >
                  18
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(-0.123287vmin)",
                    top: "calc(44.4823vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "-1.99821vmin", top: "44.5899vmin" }}
                >
                  97-98
                </div>
                <div
                  className="number small"
                  id={97}
                  style={{ left: "2.00164vmin", top: "44.6248vmin" }}
                >
                  {reduceTo22(tenthSemi + firstP)}
                </div>
                <div
                  className="outer-point small"
                  style={{
                    left: "calc(0.0637783vmin)",
                    top: "calc(40.7574vmin)",
                  }}
                />
                <div
                  className="label"
                  style={{ left: "-1.80283vmin", top: "40.6994vmin" }}
                >
                  98-99
                </div>
                <div
                  className="number small"
                  id={98}
                  style={{ left: "2.18039vmin", top: "41.0654vmin" }}
                >
                  {reduceTo22(reduceTo22(tenthSemi + firstP) + firstP)}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default Star;
